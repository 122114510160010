import React, { useState } from "react";
import Item from "./Item";

function Items({ items, locale }) {
    const [search, setSearch] = useState("");

    return (
        <div className="max-w-screen-lg m-auto bg-slate-100 rounded-md p-2 mb-5">
            <div className="p-2 mb-5">
                <h1 className="font-bold text-lg mb-4 text-slate-700">
                    Search items:
                </h1>
                <input
                    type="text"
                    className="bg-white rounded-lg w-full lg:w-2/3 px-4 py-2 mb-2 mr-4 text-sm focus:outline-none focus:ring focus:ring-slate-500"
                    placeholder="Example: Fries"
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
                {(search
                    ? items.filter((item) =>
                          item.Name.toLowerCase().includes(search.toLowerCase())
                      )
                    : items
                ).map((item) =>
                    item.Variations[0].BasePrice === 0 ? null : (
                        <Item key={item.Id} item={item} locale={locale} />
                    )
                )}
            </div>
        </div>
    );
}

export default Items;
