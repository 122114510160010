import React, { useState } from "react";

function Form({ setItems, setError, locale }) {
    const [seo, setSeo] = useState("");

    const submitSEO = async (e) => {
        e.preventDefault();

        setError("");

        if (seo === "") {
            setError("You must enter a restaurant SEO.");
            return;
        }

        const data = fetch(
            `${locale.apiEndpoint}/${seo}_${locale.countryCode}_items.json`
        )
            .then((response) => response.json())
            .then((data) => {
                setItems(data.Items);
            })
            .catch((e) => {
                setError(
                    "There was a problem accessing that menu - Please check the SEO is correct, and that you're using the correct market."
                );
                return;
            });
    };

    const clearData = async (e) => {
        e.preventDefault();

        setSeo("");
        setItems([]);
    };

    return (
        <div>
            <form className="mt-8 mb-2 max-w-screen-lg" onSubmit={submitSEO}>
                <h1 className="font-bold text-lg mb-4 text-slate-700">
                    Restaurant SEO name:
                </h1>
                <div>
                    <input
                        className="bg-white rounded-lg px-4 py-2 mb-2 mr-4 text-sm focus:outline-none focus:ring focus:ring-slate-500"
                        type="text"
                        placeholder="SEO"
                        value={seo}
                        onChange={(e) => {
                            setSeo(e.target.value.toLowerCase());
                        }}
                    />
                    <button
                        className="bg-orange-500 text-white py-2 px-4 mr-3 rounded-md items-start"
                        type="submit"
                    >
                        Submit
                    </button>
                    {seo && (
                        <button
                            className="bg-red-500 text-white py-2 px-4 rounded-md"
                            onClick={clearData}
                        >
                            Clear
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
}

export default Form;
