import React from "react";

function Item({ item, locale }) {
    let gbp = Intl.NumberFormat(locale.currencyFormat, {
        style: "currency",
        currency: locale.currency,
    });

    const truncate = (str) => {
        return str.length > 150 ? str.substring(0, 149) + "..." : str;
    };

    return (
        <div className="bg-slate-200 min-h-52 rounded-md p-2">
            <h1 className="font-medium text-slate-800">{item.Name}</h1>
            <h3 className="text-xs text-slate-500 mb-3">
                {truncate(item.Description)}
            </h3>
            {item.Variations.length > 1 ? (
                item.Variations.map((variant) => (
                    <>
                        <h2 className="text-base text-slate-700">
                            {variant.Name} -{" "}
                            <span className="font-bold text-orange-500">
                                {gbp.format(variant.BasePrice)}
                            </span>
                        </h2>
                    </>
                ))
            ) : (
                <>
                    <h2 className="text-base font-bold text-orange-500">
                        {gbp.format(item.Variations[0].BasePrice)}
                        {item.Variations[0].BasePrice < 1 && (
                            <p className="bg-red-400 rounded-md p-2 text-xs text-white font-bold mt-2">
                                Warning, This item's pricing may be inaccurate,
                                search for duplicate listing before processing
                                compensation.
                            </p>
                        )}
                    </h2>
                </>
            )}
        </div>
    );
}

export default Item;
