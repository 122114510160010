import React from "react";

function LocaleSelector({ locales, setLocale, setModalOpen }) {
    const updateLocale = (e) => {
        const buttonValue = e.currentTarget.value;
        console.log(buttonValue);
        setLocale(locales[buttonValue]);
        setModalOpen(false);
    };

    return (
        <ul className="bg-slate-100 py-4 px-2 rounded-lg absolute top-0 left-0 p-0 m-0 inline-block">
            <li className="mb-2">
                <button
                    className="h-10 w-10 bg-white rounded-full object-contain p-0.5"
                    value="uk"
                    onClick={updateLocale}
                >
                    <img src={"./img/united-kingdom.png"} />
                </button>
            </li>
            <li className="mb-2">
                <button
                    className="h-10 w-10 bg-white rounded-full object-contain p-0.5"
                    value="ie"
                    onClick={updateLocale}
                >
                    <img src={"./img/ireland.png"} />
                </button>
            </li>
            <li className="mb-2">
                <button
                    className="h-10 w-10 bg-white rounded-full object-contain p-0.5"
                    value="it"
                    onClick={updateLocale}
                >
                    <img src={"./img/italy.png"} />
                </button>
            </li>
            <li className="mb-2">
                <button
                    className="h-10 w-10 bg-white rounded-full object-contain p-0.5"
                    value="es"
                    onClick={updateLocale}
                >
                    <img src={"./img/spain.png"} />
                </button>
            </li>
            <li>
                <button
                    className="h-10 w-10 bg-white rounded-full object-contain p-0.5"
                    value="au"
                    onClick={updateLocale}
                >
                    <img src={"./img/australia.png"} />
                </button>
            </li>
        </ul>
    );
}

export default LocaleSelector;
