import { useState } from "react";
import "./App.css";
import Form from "./components/Form";
import Items from "./components/Items";
import locales from "./locale.json";
import LocaleSelector from "./components/LocaleSelector";

function App() {
    const [items, setItems] = useState([]);
    const [error, setError] = useState("");
    const [locale, setLocale] = useState(locales.uk);
    const [modalOpen, setModalOpen] = useState(false);

    console.log(locale);

    return (
        <div className="App p-5">
            <div className="max-w-full bg-orange-500 flex justify-center items-center rounded-md py-4 px-2">
                <div className="header">
                    <img
                        src="./img/jetlogo.png"
                        className="w-40 md:w-60 lg:w-80"
                        alt="JET Logo"
                    />
                </div>
            </div>
            <div className="max-w-screen-lg m-auto bg-slate-100 rounded-md py-4 px-2 my-5 relative">
                {error && (
                    <div className="bg-red-500 py-2 px-4 rounded-md mt-0 mb-5 text-white text-sm">
                        {error}
                    </div>
                )}
                <button
                    className="h-10 w-10 bg-white rounded-full absolute top-4 left-4 object-contain p-0.5"
                    onClick={() => setModalOpen(!modalOpen)}
                >
                    <img src={`./img/${locale.flag}.png`} />
                </button>
                {modalOpen && (
                    <LocaleSelector
                        locales={locales}
                        setLocale={setLocale}
                        setModalOpen={setModalOpen}
                    />
                )}
                <h1 className="font-bold text-2xl text-slate-700">
                    Menu Finder - {locale.marketName}
                </h1>
                <Form setItems={setItems} setError={setError} locale={locale} />
            </div>
            {items && items.length > 0 && (
                <Items items={items} locale={locale} />
            )}
            <div className="max-w-full text-center">
                <p>Made with 🧡 by Reece Devonport</p>
            </div>
        </div>
    );
}

export default App;
